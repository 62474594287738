import '../scss/main.scss';
import './_common.js';

import Vue from './_vue-signleton';
import { debounce } from 'debounce';
import Helpers from './util/helpers.js';
import Assets from './util/assets.js';
import analytics from './util/analytics.js';
import gdpr from './util/gdpr.js';

Vue.setOptions({
  data: {
    isAssetModalOpen: false,
    isDownloadModalOpen: false,
    addedMobileScrollHandler: false,
    activeItem: '',
    pagePath: ''
  },

  mounted: function() {
    gdpr.onChange(() => {
      this.gdprCheck();
    });

    if (window.location.hash) {
      this.openAssetModal(window.location.hash.slice(1));
    }

    this.resizeHandler();
    window.addEventListener('popstate', this.navChangeHandler);
    window.addEventListener('resize', debounce(this.resizeHandler, 500));
    window.addEventListener('keyup', this.escKeyHandler);
  },

  methods: {
    /**
     * Opens the modal with the given content and update the history.
     * @param contentUrl The url of the content to show.
     */
    openAssetModal(contentUrl) {
      this.isAssetModalOpen = true;
      const contentId = parseInt(contentUrl, 10);
      this.activeItem = contentId;
      if (!window.location.hash) {
        let link = window.location.pathname + window.location.search + '#' + contentUrl;
        history.pushState({}, document.title, link);
      }

      this.trackCardClick(document.getElementById(`modal-link-${contentId}`));
      this.$nextTick(() => {
        let videoPlaceholder = document.querySelector(
          '#modal-content-' + contentId + ' .video-placeholder'
        );
        if (videoPlaceholder) {
          Assets.renderVideo(videoPlaceholder, true);
        }

        this.gdprCheck();
      });
    },

    /**
     * Close the modal and update the history.
     */
    closeAssetModal() {
      // If the modal is closed don't do anything
      if (!this.isAssetModalOpen) {
        return;
      }
      this.isAssetModalOpen = false;
      // Simple way to stop embeds from playing is to reload the content.
      // Perhaps revisit for youtube/vimeo:
      // https://developers.google.com/youtube/iframe_api_reference
      const el = document.getElementById('modal-content-' + this.activeItem);
      if (el && el.classList.contains('social')) {
        // eslint-disable-next-line no-self-assign
        el.innerHTML = el.innerHTML;
      }
      Assets.pauseAllVideos();

      this.activeItem = '';
      // remove the hash if we have it.
      if (window.location.hash) {
        history.pushState({}, document.title, window.location.pathname + window.location.search);
      }
    },

    /**
     * Close the popup modal.
     */
    closeDownloadModal() {
      this.isDownloadModalOpen = false;
    },

    /**
     * Handle the user navigation, (back and forward buttons)
     */
    navChangeHandler: function() {
      if (window.location.hash && !this.isAssetModalOpen) {
        this.openAssetModal(window.location.hash.slice(1));
      } else {
        this.closeAssetModal();
      }
    },

    /**
     * Adds the active to the middle card on mobile scroll.
     * @param cards The cards node list
     */
    mobileScrollHandler: function(cards) {
      // Set a flag so we don't add multiple scroll handlers.
      this.addedMobileScrollHandler = true;

      cards.forEach((el) => {
        let middle = window.innerHeight / 2,
          rect = el.getBoundingClientRect(),
          offset = el.clientHeight / 2 + window.innerHeight * 0.15;

        // Make sure the active class is added to the middle of the screen.
        if (rect.top >= middle - offset && rect.bottom <= middle + offset) {
          el.classList.add('active');
        } else {
          el.classList.remove('active');
        }
      });
    },

    /**
     * The resize handler.
     */
    resizeHandler: function() {
      if (Helpers.isUserMobile() && !this.addedMobileScrollHandler) {
        let cards = document.querySelectorAll('.campaign .card');
        window.addEventListener(
          'scroll',
          debounce(() => this.mobileScrollHandler(cards), 10)
        );
      }
    },

    escKeyHandler: function(event) {
      if (this.isDownloadModalOpen && event.keyCode === 27) {
        this.closeDownloadModal();
      } else if (this.isAssetModalOpen && event.keyCode === 27) {
        this.closeAssetModal();
      }
    },

    /**
     * Track a card click.
     * @param {Element} element The card <a> tag element.
     */
    trackCardClick: function(element) {
      if (element) {
        analytics.trackEvent('Campaign Item Click', {
          action: element.dataset.action,
          label: element.dataset.label
        });
      }
    },

    /**
     * Click event handler for the cards.
     * @param {Event} event The click event.
     */
    onCardClick: function(event) {
      const el = event.currentTarget;
      if (!el.getAttribute('target')) {
        this.trackCardClick(event.currentTarget);
        // If not opening in a new tab/window, allow our event to track before redirecting.
        if (!event.metaKey && !event.ctrlKey && !event.altKey) {
          event.preventDefault();
          setTimeout(() => {
            document.location.href = el.getAttribute('href');
          }, 100);
        }
      }
    },

    /**
     * Check if GDPR settings allow us enable any visible iframe placeholders.
     */
    gdprCheck: function() {
      // TODO revert back functional when we have them.
      if (this.activeItem && gdpr.areFunctionalAllowed()) {
        gdpr.enableEmbeds(document.getElementById('modal-content-' + this.activeItem));
      }
    }
  },

  beforeDestroy: function() {
    window.removeEventListener('popstate', this.navChangeHandler);
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('keyup', this.escKeyHandler);
  }
});
